const { SET_STUDENTS_DATA } = require('redux/actions/Students');

const INIT_STATE = null;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_STUDENTS_DATA: {
      return action.payload;
    }
    default:
      return state;
  }
};
