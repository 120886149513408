import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export default function NoStyledLink(props) {
  const useStyles = makeStyles((theme) => ({
    underlineNone: {
      textDecoration: 'none',
      color: 'inherit',
      '&:visited': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  }));
  const classes = useStyles(props);
  return (
    <Link className={clsx(classes.underlineNone)} {...props}>
      {props.children}
    </Link>
  );
}
