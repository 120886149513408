import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import AppContext from '../../../@crema/utility/AppContext';
// import { ThemeMode } from '../../constants/AppEnums';

const AppLogo = () => {
  // const { themeMode } = useContext(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 70,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <img
        className={classes.logo}
        src={
          // themeMode === ThemeMode.DARK
          //   ? require('assets/images/logo-white-with-name.png')
          //   : require('assets/images/logo-with-name.png')
          require('assets/images/logo.jpeg')
        }
        alt='crema-logo'
      />
    </Box>
  );
};

export default AppLogo;
