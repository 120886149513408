import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF } = ACCESS;

export const metadataPagesConfig = [
  {
    auth: [ADMIN, STAFF],
    routes: [
      {
        path: '/metadata',
        exact: true,
        component: React.lazy(() => import('./MetaDataPage.js')),
      },
    ],
  },
];
