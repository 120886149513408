import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';
import { ContentView } from '../../index';
import BG from '../../../assets/images/auth-background.jpg';
import useStyles from '../../../shared/jss/common/common.style';
import { useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { NavStyle } from 'shared/constants/AppEnums';

const useStyle = makeStyles(() => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    background: `url(${BG}) no-repeat center center`,
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

const CremaLayout = ({ children }) => {
  useStyles();
  const { navStyle, routes } = useContext(AppContext);
  const location = useLocation();
  const branch = matchRoutes(routes, location.pathname);
  const route = branch[0].route;
  const noSideBar = route.noSideBar;
  const authUser = useSelector(({ auth }) => auth.user);
  const AppLayout = Layouts[navStyle];
  const HorLayout = Layouts[NavStyle.H_DEFAULT];
  const classes = useStyle();

  const renderLayout = useCallback(() => {
    if (authUser) {
      return <AppLayout>{children}</AppLayout>;
    } else {
      if (noSideBar) {
        return (
          <HorLayout>
            <ContentView />
          </HorLayout>
        );
      } else {
        return (
          <Box className={classes.appAuth}>
            <ContentView />
          </Box>
        );
      }
    }
  }, [noSideBar, children, authUser, classes.appAuth]);
  return renderLayout();
};

export default React.memo(CremaLayout);
