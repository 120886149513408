const { SET_META_DATA } = require('redux/actions/MetaData');

const INIT_STATE = null;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_META_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
