import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, VISA_REVIEWER } = ACCESS;
export const visaApplicationPagesConfig = [
  {
    routes: [
      {
        noSideBar: true,
        path: '/visa/applications/request/:applicationId',
        exact: true,
        component: React.lazy(() => import('./VisaApplicationForm.js')),
      },
      {
        auth: [ADMIN, VISA_REVIEWER],
        path: '/visa/applications',
        exact: true,
        component: React.lazy(() => import('./VisaApplicationsList.js')),
      },
      {
        noSideBar: true,
        path: '/visa/applications/add',
        exact: true,
        component: React.lazy(() => import('./VisaApplicationAddPage.js')),
      },
    ],
  },
];
