import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { AGENT, ADMIN, STAFF, ADMISSION_COUNSELOR } = ACCESS;

export const applicationsPagesConfig = [
  {
    auth: [ADMIN, STAFF, AGENT, ADMISSION_COUNSELOR],
    routes: [
      {
        path: '/applications',
        exact: true,
        component: React.lazy(() => import('./ApplicationsPage.js')),
      },
    ],
  },
  {
    auth: [ADMIN, STAFF, AGENT, ADMISSION_COUNSELOR],
    routes: [
      {
        path: '/applications/:applicationId',
        exact: true,
        component: React.lazy(() => import('./ApplicationDetailPage.js')),
      },
    ],
  },
];
