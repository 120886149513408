export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_SEARCH_SCHOOL_PROGRAMS = 'SET_SEARCH_SCHOOL_PROGRAMS';

export const setSearchCriteria = (criterialValues) => {
  return {
    type: SET_SEARCH_CRITERIA,
    payload: criterialValues,
  };
};

export const setSearchSchoolPrograms = (schoolPrograms) => {
  return {
    type: SET_SEARCH_SCHOOL_PROGRAMS,
    payload: schoolPrograms,
  };
};
