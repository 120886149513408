import React from 'react';
import { ALL_ACCESS } from 'shared/constants/AppEnums.js';

export const resetPasswordPagesConfig = [
  {
    auth: ALL_ACCESS,
    routes: [
      {
        path: '/resetpassword',
        exact: true,
        component: React.lazy(() => import('./ResetPassword.js')),
      },
    ],
  },
];
