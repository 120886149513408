import React from 'react';
import { ALL_ACCESS, ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, AGENT } = ACCESS;

export const schoolPagesConfig = [
  {
    auth: [ADMIN, STAFF],
    routes: [
      {
        path: '/admin/schools',
        exact: true,
        component: React.lazy(() =>
          import('./SchoolsAdminPage/SchoolsAdminPage.js'),
        ),
      },
    ],
  },
  {
    auth: [ADMIN, STAFF],
    routes: [
      {
        path: '/admin/schools/add',
        exact: true,
        component: React.lazy(() =>
          import('./SchoolsAdminPage/SchoolBasicInfo.js'),
        ),
      },
    ],
  },
  {
    auth: [ADMIN, STAFF],
    routes: [
      {
        // dashboard, basicInfo, programs
        path: '/admin/schools/:schoolId/:subSection/:programId?',
        component: React.lazy(() =>
          import('./SchoolsAdminPage/SchoolAdminPage.js'),
        ),
      },
    ],
  },
  {
    auth: [ADMIN, STAFF, AGENT],
    routes: [
      {
        // dashboard, basicInfo, programs
        path: '/admin/schools/:schoolId/programs/:programId',
        exact: true,
        component: React.lazy(() =>
          import('./SchoolsAdminPage/SchoolAdminPage.js'),
        ),
      },
    ],
  },
  {
    auth: ALL_ACCESS,
    routes: [
      {
        path: '/schools/:schoolId',
        exact: true,
        component: React.lazy(() => import('./SchoolPage/SchoolPage.js')),
      },
    ],
  },
  {
    auth: ALL_ACCESS,
    routes: [
      {
        path: '/schools/:schoolId/programs/:programId',
        exact: true,
        component: React.lazy(() => import('../programs/SchoolProgramPage.js')),
      },
    ],
  },
];
