import jwtAxios from '@crema/services/auth';

export const getMetadata = () => {
  return jwtAxios.get('/metadata').then((resp) => {
    return resp.data;
  });
};

export const addDisciplines = ({
  discipline,
  discipline_name_en,
  discipline_description_en,
  discipline_name_cn,
  discipline_description_cn,
}) => {
  return jwtAxios.post('/metadata/disciplines/add', {
    discipline,
    discipline_name_en,
    discipline_description_en,
    discipline_name_cn,
    discipline_description_cn,
  });
};

export const addSubDisciplines = ({
  discipline_id,
  sub_discipline,
  sub_discipline_name_en,
  sub_discipline_description_en,
  sub_discipline_name_cn,
  sub_discipline_description_cn,
}) => {
  return jwtAxios.post('/metadata/disciplines_sub/add', {
    discipline_id,
    sub_discipline,
    sub_discipline_name_en,
    sub_discipline_description_en,
    sub_discipline_name_cn,
    sub_discipline_description_cn,
  });
};

export const addGradeScales = ({ scale_type }) => {
  return jwtAxios.post('/metadata/grade_scales/add', {
    scale_type,
  });
};

export const addIntervalUnits = ({ unit }) => {
  return jwtAxios.post('/metadata/interval_units/add', {
    unit,
    // unit_en,
    // unit_cn,
  });
};

export const addProgramLevel = ({ program_level }) => {
  return jwtAxios.post('/metadata/program_levels/add', {
    program_level,
  });
};

export const addSchoolType = ({
  school_type,
  // school_type_en,
  // school_type_cn,
  note,
}) => {
  return jwtAxios.post('/metadata/school_types/add', {
    school_type,
    // school_type_en,
    // school_type_cn,
    note,
  });
};

export const addFileType = ({ file_type }) => {
  return jwtAxios.post('/metadata/file_types/add', {
    file_type,
  });
};

export const addVisaType = (data) => {
  return jwtAxios.post('/metadata/visa_types/add', data);
};

export const updateVisaType = (
  id,
  template_name,
  template_link,
  visa_application_fee,
) => {
  return jwtAxios.patch(`/metadata/visa_types/${id}`, {
    template_name,
    template_link,
    visa_application_fee,
  });
};
