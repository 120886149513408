import {
  isAdmin,
  isAdmissionCounselor,
  isAgent,
  isAgentManager,
  isEditor,
  isStaff,
  isVisaReviewer,
} from 'shared/utils/utils';

const { createSelector } = require('reselect');

export const selectUser = (state) => state.auth && state.auth.user;

export const selectUserId = createSelector(selectUser, (user) => {
  return user && user.uuid;
});

export const selectUserAccess = createSelector(selectUser, (user) => {
  return user && user.access;
});

export const selectIsAdmin = createSelector(selectUserAccess, (access) =>
  isAdmin(access),
);

export const selectIsStaff = createSelector(selectUserAccess, (access) =>
  isStaff(access),
);

export const selectIsSuperUser = createSelector(
  selectIsAdmin,
  selectIsStaff,
  (isAdmin, isStaff) => isAdmin || isStaff,
);

export const selectIsAdmissionCounselor = createSelector(
  selectUserAccess,
  (access) => isAdmissionCounselor(access),
);

export const selectIsAgentManager = createSelector(selectUserAccess, (access) =>
  isAgentManager(access),
);

export const selectIsAgent = createSelector(selectUserAccess, (access) =>
  isAgent(access),
);

export const selectIsEditor = createSelector(selectUserAccess, (access) =>
  isEditor(access),
);

export const selectIsVisaReviewer = createSelector(selectUserAccess, (access) =>
  isVisaReviewer(access),
);
