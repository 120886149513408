import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Auth from './Auth';
import MetaData from './MetaData';
import Staffs from '../../modules/staffs/reducer';
import Students from './Students';
import Search from './Search';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    staffs: Staffs,
    metaData: MetaData,
    students: Students,
    search: Search,
  });
