export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
};
export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_LIGHT_NAV: 'hor-light-nav',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const RouteTransition = {
  NONE: 'none',
  FADE: 'fade',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: 'Gilroy-Light',
  REGULAR: 'Gilroy-Regular',
  MEDIUM: 'Gilroy-Medium',
  BOLD: 'Gilroy-Bold',
  EXTRA_BOLD: 'Gilroy-ExtraBold',
};

export const ACCESS = {
  ADMIN: 0,
  STAFF: 1,
  ADMISSION_COUNSELOR: 2,
  AGENT_MANAGER: 3,
  AGENT: 4,
  EDITOR: 5,
  VISA_REVIEWER: 6,
};

export const ALL_ACCESS = [
  ACCESS.ADMIN,
  ACCESS.STAFF,
  ACCESS.ADMISSION_COUNSELOR,
  ACCESS.AGENT_MANAGER,
  ACCESS.AGENT,
  ACCESS.EDITOR,
  ACCESS.VISA_REVIEWER,
];

export const APPLICATION_STEPS = {
  PREPARE: 0,
  PRE_ASSESS: 1,
  PAYMENT: 2,
  SUBMITTED_TO_SCHOOL: 3,
  SCHOOL_REVIEWING: 4,
  FINAL_DECISION: 5,
};

export const APPLICATION_STEP_MESSAGE_IDS = {
  [APPLICATION_STEPS.PREPARE]: 'application.prepare',
  [APPLICATION_STEPS.PRE_ASSESS]: 'application.preAssessing',
  [APPLICATION_STEPS.PAYMENT]: 'application.payment',
  [APPLICATION_STEPS.SUBMITTED_TO_SCHOOL]: 'application.submittingToSchool',
  [APPLICATION_STEPS.SCHOOL_REVIEWING]: 'application.SchoolReviewing',
  [APPLICATION_STEPS.FINAL_DECISION]: 'application.FinalDecision',
};

export const VISA_APPLICATION_STEPS = {
  PREPARE: 0,
  SUBMITTED: 1,
  PAYMENT: 2,
  PROCESSED: 3,
  FINISHED: 4,
};

export const VISA_APPLICATION_STEP_MESSAGE_IDS = {
  [VISA_APPLICATION_STEPS.PREPARE]: 'visa.application.prepare',
  [VISA_APPLICATION_STEPS.SUBMITTED]: 'visa.application.submitted',
  [VISA_APPLICATION_STEPS.PAYMENT]: 'visa.application.payment',
  [VISA_APPLICATION_STEPS.PROCESSED]: 'visa.application.processed',
  [VISA_APPLICATION_STEPS.FINISHED]: 'visa.application.finished',
};
