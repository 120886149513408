import { LOAD_STAFFS } from './actionTypes';

const INIT_STATE = {
  staffs: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_STAFFS: {
      return {
        ...state,
        staffs: action.payload,
      };
    }

    default:
      return state;
  }
};
