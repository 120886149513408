import React from 'react';

import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, AGENT, STAFF, ADMISSION_COUNSELOR } = ACCESS;

export const studentPagesConfig = [
  {
    auth: [ADMIN, STAFF, AGENT, ADMISSION_COUNSELOR],
    routes: [
      {
        path: '/students/',
        exact: true,
        component: React.lazy(() => import('./StudentsPage')),
      },
      {
        path: '/students/:studentId/:subSection',
        exact: true,
        component: React.lazy(() => import('./StudentPage')),
      },
      {
        path: '/students/add',
        exact: true,
        component: React.lazy(() => import('./studentProfile')),
      },
    ],
  },
];
