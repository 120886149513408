import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectMetaData = (state) => state.metaData;

export const selectDisciplines = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.disciplines;
});

export const selectDisciplinesMap = createSelector(
  selectDisciplines,
  (disciplines) => {
    return _.keyBy(disciplines, 'id');
  },
);

export const selectSubDisciplines = createSelector(
  selectMetaData,
  (metaData) => {
    return metaData && metaData.sub_disciplines;
  },
);

export const selectSubDisciplinesMap = createSelector(
  selectSubDisciplines,
  (subDisciplines) => {
    return _.keyBy(subDisciplines, 'id');
  },
);

export const selectGradeScales = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.grade_scales;
});

export const selectIntervalUnits = createSelector(
  selectMetaData,
  (metaData) => {
    return metaData && metaData.interval_units;
  },
);

export const selectProgramLevels = createSelector(
  selectMetaData,
  (metaData) => {
    return metaData && metaData.program_levels;
  },
);

export const selectSchoolTypes = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.school_types;
});

export const selectSchoolTypesMap = createSelector(
  selectSchoolTypes,
  (schoolType) => {
    return _.keyBy(schoolType, 'id');
  },
);

export const selectFileTypes = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.file_types;
});

export const selectFileTypesMap = createSelector(
  selectFileTypes,
  (fileTypes) => {
    return _.keyBy(fileTypes, 'id');
  },
);

export const selectVisaTypes = createSelector(selectMetaData, (metaData) => {
  return metaData && metaData.visa_types;
});

export const selectVisaTypesMap = createSelector(
  selectVisaTypes,
  (visaTypes) => {
    return _.keyBy(visaTypes, 'id');
  },
);
