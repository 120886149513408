import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';

const AppLocale = {
  en: enLang,
  zh: zhLang,
  es: esLang,
  fr: frLang,
};

export default AppLocale;
