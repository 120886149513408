import React from 'react';
import { Redirect } from 'react-router-dom';
import { createRoutes } from '../@crema/utility/Utils';
import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { initialUrl } from '../shared/constants/AppConst';
import { staffPagesConfig } from './staffs/routesConfig';
import { studentPagesConfig } from './students/routesConfig';
import { schoolPagesConfig } from './schools/routesConfig';
import { metadataPagesConfig } from './metadata/routesConfig';
import { searchPagesConfig } from './Search/routesConfig';
import { applicationsPagesConfig } from './applications/routesConfig';
import { userPagesConfig } from './users/routesConfig';
import { resetPasswordPagesConfig } from './resetPassword/routesConfig';
import { visaApplicationPagesConfig } from './visaApplications/routesConfig';

// reminder: add page config here
const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...staffPagesConfig,
  ...studentPagesConfig,
  ...schoolPagesConfig,
  ...metadataPagesConfig,
  ...searchPagesConfig,
  ...applicationsPagesConfig,
  ...userPagesConfig,
  ...resetPasswordPagesConfig,
  ...visaApplicationPagesConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
