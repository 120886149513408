import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const {
  ADMIN,
  STAFF,
  AGENT,
  ADMISSION_COUNSELOR,
  EDITOR,
  AGENT_MANAGER,
} = ACCESS;

export const searchPagesConfig = [
  {
    auth: [ADMIN, STAFF, AGENT, ADMISSION_COUNSELOR, EDITOR, AGENT_MANAGER],
    routes: [
      {
        path: '/search',
        exact: true,
        component: React.lazy(() => import('./SearchPage')),
      },
    ],
  },
];
