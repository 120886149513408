import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setInitialPath } from '../../redux/actions';
import { matchRoutes } from 'react-router-config';
import AppContext from './AppContext';
import { useAuthToken, useAuthUser } from './AppHooks';
import { Loader } from '../index';
import PropTypes from 'prop-types';
import { initialUrl } from '../../shared/constants/AppConst';
import jwtAxios from '@crema/services/auth';
import _ from 'lodash';

const AuthRoutes = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useAuthUser();
  const { routes } = useContext(AppContext);

  const [token, loading] = useAuthToken();
  const initialPath = useSelector(({ settings }) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/forget-password',
          '/new-password',
        ].indexOf(pathname) === -1
      ) {
        dispatch(setInitialPath(pathname));
      }
    }

    setInitPath();
  }, [dispatch, initialPath, loading, pathname, token]);

  useEffect(() => {
    if (!loading) {
      if (token && !jwtAxios.defaults.headers.common['auth-token']) {
        jwtAxios.defaults.headers.common['auth-token'] = token;
      }
      if (!token && currentRoute.auth && currentRoute.auth.length >= 1) {
        history.push('/signin');
      } else if (
        (pathname === '/signin' ||
          pathname === '/signup' ||
          pathname === '/confirm-signup' ||
          pathname === '/reset-password' ||
          pathname === '/forget-password' ||
          pathname === '/new-password') &&
        token
      ) {
        if (pathname === '/') {
          history.push(initialUrl);
        } else if (
          initialPath !== '/' ||
          initialPath !== '/signin' ||
          initialPath !== '/signup'
        ) {
          history.push(initialPath);
        } else {
          history.push(initialUrl);
        }
      } else if (
        token &&
        currentRoute.auth &&
        !_.includes(currentRoute.auth, user.access)
      ) {
        history.push('/error-pages/error-403');
      }
    }
  }, [
    token,
    loading,
    pathname,
    initialPath,
    currentRoute.auth,
    history,
    user.access,
  ]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
