import React, { useState, useEffect } from 'react';
import Loader from '@crema/core/Loader';
import { getMetadata } from 'shared/apiServices/metadataApi';
import { useAuthToken } from './AppHooks';
import { useDispatch, useSelector } from 'react-redux';
import { setMetaDataAction } from 'redux/actions/MetaData';
import { selectMetaData } from 'redux/selectors/MetaData';
import { useLocation } from 'react-router-dom';

const MetaDataLoader = (props) => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const metaData = useSelector(selectMetaData);
  const [token, authLoading] = useAuthToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (metaData) {
      return;
    }
    if (!metaData && token && !authLoading && !loading) {
      setLoading(true);
      getMetadata()
        .then((metaData) => {
          dispatch(setMetaDataAction(metaData));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authLoading, dispatch, pathname, metaData, token, loading]);
  return loading ? <Loader /> : props.children;
};

export default MetaDataLoader;
