const {
  SET_SEARCH_CRITERIA,
  SET_SEARCH_SCHOOL_PROGRAMS,
} = require('redux/actions/Search');

const INIT_STATE = {
  criteria: null,
  schoolPrograms: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH_CRITERIA: {
      return {
        ...state,
        criteria: action.payload,
      };
    }
    case SET_SEARCH_SCHOOL_PROGRAMS: {
      return {
        ...state,
        schoolPrograms: action.payload,
      };
    }
    default:
      return state;
  }
};
