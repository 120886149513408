import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN, STAFF, AGENT_MANAGER, ADMISSION_COUNSELOR } = ACCESS;

const createUserRoute = (auth, basePath, moduleName) => {
  return [
    {
      auth: auth,
      routes: [
        {
          path: `${basePath}`,
          exact: true,
          component: React.lazy(() =>
            import(`./${moduleName}/${moduleName}List.js`),
          ),
        },
      ],
    },
    {
      auth: auth,
      routes: [
        {
          path: `${basePath}/:userId`, // userId can stand for both 'add' and 'uuid'
          exact: true,
          component: React.lazy(() =>
            import(`./${moduleName}/${moduleName}Info.js`),
          ),
        },
      ],
    },
  ];
};

const agentRoutes = createUserRoute(
  [ADMIN, STAFF, AGENT_MANAGER],
  '/agents',
  'Agent',
);

const admissionCounselorRoutes = createUserRoute(
  [ADMIN, STAFF],
  '/admissioncounselors',
  'AdmissionCounselor',
);

const agentManagerRoutes = createUserRoute(
  [ADMIN, STAFF, ADMISSION_COUNSELOR],
  '/agentmanagers',
  'AgentManager',
);

const editorRoutes = createUserRoute([ADMIN, STAFF], '/editors', 'Editor');

const visaReviewerRoutes = createUserRoute(
  [ADMIN, STAFF],
  '/visa-reviewers',
  'VisaReviewer',
);

export const userPagesConfig = [
  ...agentRoutes,
  ...admissionCounselorRoutes,
  ...agentManagerRoutes,
  ...editorRoutes,
  ...visaReviewerRoutes,
];
