import React, { useContext } from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import { ContentView } from '../../../index';
// import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import { LayoutType } from '../../../../shared/constants/AppEnums';
import { useTheme } from '@material-ui/core';

const StandardLayout = (props) => {
  const classes = useStyles(props);
  const { footer, layoutType, footerType } = useContext(AppContext);
  const theme = useTheme();
  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}
    >
      <AppSidebar />

      <Box className={classes.mainContent}>
        {/* <Hidden mdDown> */}
        <Box
          className={clsx(
            theme.breakpoints.up('md')
              ? classes.mainContainer
              : classes.mainContainerFull,
          )}
        >
          <AppHeader />
          <ContentView />
          <AppFixedFooter />
        </Box>
        {/* </Hidden> */}

        {/* <Hidden lgUp>
        <Box className={classes.mainContainerFull}>
          <AppHeader />
          <ContentView />
          <AppFixedFooter />
        </Box>
        </Hidden> */}
      </Box>
    </Box>
  );
};

export default StandardLayout;
