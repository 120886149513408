import {
  isStaff,
  isAdmin,
  isAdmissionCounselor,
  isAgentManager,
  isAgent,
  isEditor,
  isVisaReviewer,
} from 'shared/utils/utils';

// All google material design icon list: https://material.io/resources/icons/?style=baseline

const routesConfig = (user) => {
  const access = user.access;
  const userChildrenRoutes = [];
  if (isAdmin(access)) {
    userChildrenRoutes.push({
      id: 'staff',
      title: 'Staffs',
      messageId: 'staffs.title',
      type: 'item',
      icon: 'person',
      url: '/staffs',
    });
  }
  if (isStaff(access) || isAdmin(access)) {
    userChildrenRoutes.push(
      {
        id: 'admissionCounselors',
        title: 'AdmissionCounselors',
        messageId: 'admissionCounselors.title',
        type: 'item',
        icon: 'people',
        url: '/admissioncounselors',
      },
      {
        id: 'agentManagers',
        title: 'agentManagers',
        messageId: 'agentManagers.title',
        type: 'item',
        icon: 'people',
        url: '/agentmanagers',
      },
      {
        id: 'agent',
        title: 'Agents',
        messageId: 'agents.title',
        type: 'item',
        icon: 'people',
        url: '/agents',
      },
      {
        id: 'editors',
        title: 'editors',
        messageId: 'editors.title',
        type: 'item',
        icon: 'people',
        url: '/editors',
      },
      {
        id: 'visa-reviewers',
        title: 'visa-reviewers',
        messageId: 'visa.reviewers.title',
        type: 'item',
        icon: 'people',
        url: '/visa-reviewers',
      },
    );
  }
  if (isAdmissionCounselor(access)) {
    userChildrenRoutes.push({
      id: 'agentManagers',
      title: 'agentManagers',
      messageId: 'agentManagers.title',
      type: 'item',
      icon: 'people',
      url: '/agentmanagers',
    });
  }
  if (isAgentManager(access)) {
    userChildrenRoutes.push({
      id: 'agent',
      title: 'Agents',
      messageId: 'agents.title',
      type: 'item',
      icon: 'people',
      url: '/agents',
    });
  }
  const menu = [];
  // users
  if (
    isStaff(access) ||
    isAdmin(access) ||
    isAdmissionCounselor(access) ||
    isAgentManager(access)
  ) {
    menu.push({
      id: 'users',
      title: 'Users',
      messageId: 'common.users',
      type: 'collapse',
      icon: 'people',
      children: userChildrenRoutes,
    });
  }
  // search
  menu.push({
    id: 'search',
    title: 'Search',
    messageId: 'common.search',
    type: 'item',
    icon: 'search',
    url: '/search',
  });
  // students
  if (isStaff(access) || isAdmin(access) || isAgent(access)) {
    menu.push({
      id: 'students',
      title: 'Students',
      messageId: 'students.title',
      type: 'item',
      icon: 'face',
      url: '/students',
    });
  }
  // applications
  if (
    isStaff(access) ||
    isAdmin(access) ||
    isAgent(access) ||
    isAdmissionCounselor(access)
  ) {
    menu.push({
      id: 'applications',
      title: 'Applications',
      messageId: 'applications.title',
      type: 'item',
      icon: 'assignment_turned_in',
      url: '/applications',
    });
  }
  if (isStaff(access) || isAdmin(access)) {
    menu.push({
      id: 'school',
      title: 'Schools',
      messageId: 'schools.title',
      type: 'item',
      icon: 'school',
      url: '/admin/schools',
    });
    menu.push({
      id: 'visaApplications',
      title: 'visaApplications',
      messageId: 'visa.application.title',
      type: 'item',
      icon: 'public',
      url: '/visa/applications',
    });
    menu.push({
      id: 'metadata',
      title: 'MetaData',
      messageId: 'metadata.title',
      type: 'item',
      icon: 'build',
      url: '/metadata',
    });
  }
  if (isEditor(access)) {
    menu.push({
      id: 'school',
      title: 'Schools',
      messageId: 'schools.title',
      type: 'item',
      icon: 'school',
      url: '/admin/schools',
    });
  }
  if (isVisaReviewer(access)) {
    menu.push({
      id: 'visaApplications',
      title: 'visaApplications',
      messageId: 'visa.application.title',
      type: 'item',
      icon: 'public',
      url: '/visa/applications',
    });
  }
  return menu;
};
export default routesConfig;
