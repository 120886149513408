import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import AppContext from '../../../utility/AppContext';

const useStyles = makeStyles((theme) => {
  const { themeStyle, footer } = useContext(AppContext);
  return {
    appMain: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      backgroundColor: theme.palette.background.default,
      paddingTop: 56,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 70,
      },
      '&.appMainFooter': {
        '& $mainContainer': {
          '& .scroll-app, & .scroll-app-detail': {
            height: 'calc(100vh - 287px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 282px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 360px) !important',
            },
          },
          '& .scroll-app-sidebar': {
            height: 'calc(100vh - 306px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 302px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 392px) !important',
            },
          },
          '& .scroll-chat-sidebar': {
            height: 'calc(100vh - 399px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 395px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 485px) !important',
            },
          },
          '& .scroll-chat-main': {
            height: 'calc(100vh - 397px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 382px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 477px) !important',
            },
          },
          '& .scroll-chat-nomain': {
            height: 'calc(100vh - 399px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 395px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 485px) !important',
            },
          },
          '& .scroll-chat-nouser': {
            height: 'calc(100vh - 226px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 222px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 193px) !important',
            },
          },
          '& .scrum-relative, & .scrum-col': {
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 218px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 280px) !important',
            },
          },
        },
        '& $mainContainerFull': {
          '& .scroll-app': {
            height: 'calc(100vh - 316px) !important',
            [theme.breakpoints.up('sm')]: {
              height: 'calc(100vh - 266px) !important',
            },
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 296px) !important',
            },
          },
          '& .scroll-app-detail': {
            height: 'calc(100vh - 266px) !important',
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 305px) !important',
            },
          },
          '& .scrum-relative, & .scrum-col': {
            height: 'calc(100vh - 204px) !important',
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 214px) !important',
            },
          },
        },
      },
      '&.appMainFixedFooter': {
        paddingBottom: 48,
        [theme.breakpoints.up('xl')]: {
          paddingBottom: 58,
        },
        '& .scrollAppSidebar': {
          height:
            themeStyle === 'Modern'
              ? 'calc(100vh - 260px) !important'
              : 'calc(100vh - 198px) !important',
          [theme.breakpoints.up('xl')]: {
            height:
              themeStyle === 'Modern'
                ? 'calc(100vh - 300px) !important'
                : 'calc(100vh - 236px) !important',
          },
        },
        '& $mainContainer': {
          paddingBottom: 1,
          '& .scroll-app, & .scroll-app-detail': {
            height: 'calc(100vh - 287px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 282px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 360px) !important',
            },
          },
          '& .scroll-app-sidebar': {
            height: 'calc(100vh - 306px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 302px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 392px) !important',
            },
          },
          '& .scroll-chat-sidebar': {
            height: 'calc(100vh - 399px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 395px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 485px) !important',
            },
          },
          '& .scroll-chat-main': {
            height: 'calc(100vh - 397px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 382px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 477px) !important',
            },
          },
          '& .scroll-chat-nomain': {
            height: 'calc(100vh - 399px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 395px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 485px) !important',
            },
          },
          '& .scroll-chat-nouser': {
            height: 'calc(100vh - 226px) !important',
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 222px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 193px) !important',
            },
          },
          '& .scrum-relative, & .scrum-col': {
            [theme.breakpoints.up('lg')]: {
              height: 'calc(100vh - 218px) !important',
            },
            [theme.breakpoints.up('xl')]: {
              height: 'calc(100vh - 280px) !important',
            },
          },
        },
        '& $mainContainerFull': {
          '& .scroll-app': {
            height: 'calc(100vh - 316px) !important',
            [theme.breakpoints.up('sm')]: {
              height: 'calc(100vh - 266px) !important',
            },
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 296px) !important',
            },
          },
          '& .scroll-app-detail': {
            height: 'calc(100vh - 266px) !important',
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 305px) !important',
            },
          },
          '& .scrum-relative, & .scrum-col': {
            height: 'calc(100vh - 204px) !important',
            [theme.breakpoints.up('md')]: {
              height: 'calc(100vh - 214px) !important',
            },
          },
        },
      },
    },
    mainContent: {
      flex: 1,
      display: 'flex',
      [theme.breakpoints.up('lg')]: {
        marginLeft: '4rem',
      },
      [theme.breakpoints.up('xl')]: {
        marginLeft: '5rem',
      },
    },
    mainContainer: {
      width: '100%',
      paddingBottom: footer ? 0 : 10,
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100vw - 4rem)',
      },
      [theme.breakpoints.up('xl')]: {
        width: 'calc(100vw - 5rem)',
      },
      '& > .scrollbar-container': {
        padding: '20px 20px 0',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          padding: '30px 32px 0',
        },
        '& .scroll-app': {
          height: 'calc(100vh - 239px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 234px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 302px) !important',
          },
        },
        '& .scroll-app-detail': {
          height: 'calc(100vh - 239px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 234px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 302px) !important',
          },
        },
        '& .scroll-app-sidebar': {
          height: 'calc(100vh - 258px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 254px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 334px) !important',
          },
        },
        '& .scroll-chat-sidebar': {
          height: 'calc(100vh - 347px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 343px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 423px) !important',
          },
        },
        '& .scroll-chat-main': {
          height: 'calc(100vh - 334px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 330px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 368px) !important',
          },
        },
        '& .scroll-chat-nomain': {
          height: 'calc(100vh - 334px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 330px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 406px) !important',
          },
        },
        '& .scroll-chat-nouser': {
          height: 'calc(100vh - 174px) !important',
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 170px) !important',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 193px) !important',
          },
        },
      },
      '& .scrum-relative': {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100vh - 148px) !important',
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 168px) !important',
        },
        [theme.breakpoints.up('xl')]: {
          height: 'calc(100vh - 234px) !important',
        },
      },
      '& .scrum-absolute': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      },
      '& .scrum-row': {
        display: 'inline-flex',
        minWidth: '100%',
        marginLeft: '-10px',
        marginRight: '-10px',
      },
      '& .scrum-col': {
        width: '280px',
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: theme.overrides.MuiCard.root.borderRadius,
        height: 'calc(100vh - 148px) !important',
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 168px) !important',
          width: '354px',
        },
        [theme.breakpoints.up('xl')]: {
          height: 'calc(100vh - 234px) !important',
        },
      },
      '& .scroll-scrum-item': {
        height: 'auto !important',
      },
    },
    mainContainerFull: {
      width: '100vw',
      paddingBottom: footer ? 0 : 10,
      '& > .scrollbar-container': {
        padding: '20px 20px 0',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          padding: '30px 32px 0',
        },
        '& .scroll-app': {
          height: 'calc(100vh - 270px) !important',
          [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 220px) !important',
          },
          [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 230px) !important',
          },
        },
        '& .scroll-app-detail': {
          height: 'calc(100vh - 220px) !important',
          [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 239px) !important',
          },
        },
        '& .scroll-chat-main': {
          height: 'calc(100vh - 324px) !important',
          [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 335px) !important',
          },
        },
      },
      '& .scrum-relative': {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 'calc(100vh - 148px) !important',
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 168px) !important',
        },
        [theme.breakpoints.up('xl')]: {
          height: 'calc(100vh - 234px) !important',
        },
      },
      '& .scrum-absolute': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      },
      '& .scrum-row': {
        display: 'inline-flex',
        minWidth: '100%',
        marginLeft: '-10px',
        marginRight: '-10px',
      },
      '& .scrum-col': {
        width: '280px',
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: theme.overrides.MuiCard.root.borderRadius,
        height: 'calc(100vh - 148px) !important',
        [theme.breakpoints.up('md')]: {
          height: 'calc(100vh - 168px) !important',
          width: '354px',
        },
        [theme.breakpoints.up('xl')]: {
          height: 'calc(100vh - 234px) !important',
        },
      },
      '& .scroll-scrum-item': {
        height: 'auto !important',
      },
    },
    boxedLayout: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1260,
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
        '& .app-sidebar': {
          position: 'absolute',
        },
        '& $mainContent': {
          width: 'calc(100% - 19rem)',
          flex: 'auto',
        },
        '& $mainContainer': {
          width: '100%',
        },
        '& .app-bar': {
          width: 'calc(100% - 5rem)',
          position: 'absolute',
          boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.12)',
        },
        '& .grid-btn': {
          fontSize: 11,
        },
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1680,
        '& $mainContent': {
          width: 'calc(100% - 21.6rem)',
        },
      },
    },
  };
});
export default useStyles;
