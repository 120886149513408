import React from 'react';
import { ACCESS } from 'shared/constants/AppEnums.js';
const { ADMIN } = ACCESS;
export const staffPagesConfig = [
  {
    auth: [ADMIN],
    routes: [
      {
        path: '/staffs/',
        exact: true,
        component: React.lazy(() => import('./StaffsPage.js')),
      },
    ],
  },
  {
    auth: [ADMIN],
    routes: [
      {
        path: '/staffs/add',
        component: React.lazy(() => import('./StaffAddPage.js')),
      },
    ],
  },
];
